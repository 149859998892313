import React from 'react';
import './BuyerLeads.css';
import { User } from 'firebase/auth';

type BuyerLeadsProps = {
    user: User | null;
};

const BuyerLeads: React.FC<BuyerLeadsProps> = ({ user }) => {
    // Du kannst "user" jetzt hier nutzen, z. B. um Leads nur für eingeloggte Händler zu zeigen
    const leads = [
        {
            id: 1,
            name: 'Lena M.',
            vehicleType: 'SUV',
            location: 'Berlin',
            activity: 'vor 3 Tagen aktiv',
            interest: 'Elektro, max. 35.000 €',
        },
        {
            id: 2,
            name: 'Tobias K.',
            vehicleType: 'Kombi',
            location: 'München',
            activity: 'gestern aktiv',
            interest: 'Hybrid, Audi A6 oder ähnlich',
        },
        {
            id: 3,
            name: 'Sarah R.',
            vehicleType: 'Limousine',
            location: 'Hamburg',
            activity: 'heute aktiv',
            interest: 'Benziner, Mercedes C-Klasse',
        },
    ];

    return (
        <div className="buyer-leads">
            <h1>Kaufinteressenten</h1>
            <p>Diese Nutzer:innen haben aktiv Suchprofile angelegt und passen zu deinen Inseraten:</p>
            <div className="lead-list">
                {leads.map((lead) => (
                    <div className="lead-card" key={lead.id}>
                        <div className="lead-avatar-wrapper">
                            <img
                                className="lead-avatar"
                                src={`https://i.pravatar.cc/150?u=${lead.name.replace(' ', '_')}`}
                                onError={(e) => {
                                    const target = e.target as HTMLImageElement;
                                    target.style.display = 'none';
                                    const fallback = target.nextElementSibling as HTMLElement;
                                    fallback.style.display = 'flex';
                                }}
                                alt={lead.name}
                            />
                            <div className="lead-avatar-fallback">
                                {lead.name.split(' ').map(n => n[0]).join('').toUpperCase()}
                            </div>
                        </div>

                        <h3>{lead.name}</h3>
                        <p><strong>Fahrzeugtyp:</strong> {lead.vehicleType}</p>
                        <p><strong>Ort:</strong> {lead.location}</p>
                        <p><strong>Aktivität:</strong> {lead.activity}</p>
                        <p><strong>Suchprofil:</strong> {lead.interest}</p>
                        <button className="btn-chat">Kontakt aufnehmen</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BuyerLeads;
