import React from 'react';
import { NavLink } from 'react-router-dom';
import './Sidebar.css';
import { useLocation, useNavigate } from 'react-router-dom';

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNav = (path: string) => {
    if (location.pathname !== path) {
      navigate(path);
    }
  };
  return (
    <div className="sidebar">
      <h2 className="sidebar-title">AutoMatch PRO</h2>
      <nav className="sidebar-nav">
        <NavLink to="/dashboard" className="sidebar-link">📊 Dashboard</NavLink>
        <NavLink to="/listings" className="sidebar-link">🚗 Listings</NavLink>
        <NavLink to="/leads" className="sidebar-link">👥 Buyer Leads</NavLink>
        <NavLink to="/messages" className="sidebar-link">📩 Messages</NavLink>
        <NavLink to="/analytics" className="sidebar-link">📈 Analytics</NavLink>
        <NavLink to="/filters" className="sidebar-link">🔍 Filters</NavLink>
        <NavLink to="/boost" className="sidebar-link">✨ Boost</NavLink>
        <NavLink to="/settings" className="sidebar-link">⚙️ Settings</NavLink>
        {/* <div className="sidebar-link" onClick={() => handleNav('/dashboard')}>📊 Dashboard</div>
        <div className="sidebar-link" onClick={() => handleNav('/listings')}>🚗 Listings</div>
        <div className="sidebar-link" onClick={() => handleNav('/leads')}>👥 Buyer Leads</div>
        <div className="sidebar-link" onClick={() => handleNav('/messages')}>📩 Messages</div>
        <div className="sidebar-link" onClick={() => handleNav('/analytics')}>📈 Analytics</div>
        <div className="sidebar-link" onClick={() => handleNav('/filters')}>🔍 Filters</div>
        <div className="sidebar-link" onClick={() => handleNav('/boost')}>✨ Boost</div>
        <div className="sidebar-link" onClick={() => handleNav('/settings')}>⚙️ Settings</div> */}
      </nav>
    </div>
  );
};

export default Sidebar;