import React from 'react';
import './Messages.css';

const Messages = () => {
  const chats = [
    {
      id: 1,
      name: 'Lena M.',
      preview: 'Ist der Audi A3 noch verfügbar?',
      time: 'heute, 10:45'
    },
    {
      id: 2,
      name: 'Tobias K.',
      preview: 'Könnten wir einen Besichtigungstermin vereinbaren?',
      time: 'gestern, 18:12'
    },
    {
      id: 3,
      name: 'Sarah R.',
      preview: 'Ich interessiere mich für den ID.4 – noch zu haben?',
      time: 'vor 2 Tagen'
    }
  ];

  return (
    <div className="messages-page">
      <h1>Nachrichten</h1>
      <p>Alle Unterhaltungen mit Interessenten im Überblick.</p>

      <div className="chat-list">
        {chats.map(chat => (
          <div className="chat-preview" key={chat.id}>
            <div className="chat-initials">{chat.name.split(' ').map(n => n[0]).join('')}</div>
            <div className="chat-info">
              <h3>{chat.name}</h3>
              <p>{chat.preview}</p>
            </div>
            <span className="chat-time">{chat.time}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Messages;
