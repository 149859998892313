// src/components/SendFeedback.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import './SendFeedback.css';
import { User } from 'firebase/auth';
// import { useFirebaseServicesNew } from '../firebaseConfig'; // Import the hook for Firebase services

interface SendFeedbackProps {
  user: User | null;
  version: string;
  buildNumber: string;
}

const SendFeedback: React.FC<SendFeedbackProps> = ({ user, version, buildNumber }) => {
  const [feedback, setFeedback] = useState('');
  const navigate = useNavigate();
  // const firebaseServices = useFirebaseServicesNew();

  const handleSendFeedback = async () => {
    if (feedback.trim() === '') {
      alert('Bitte geben Sie Ihr Feedback ein.');
      return;
    }

    if (user) {
      try {
        // if (firebaseServices?.db) {
          await addDoc(collection(db, 'feedback'), {
            uid: user.uid,
            feedback,
            version,
            buildNumber,
            timestamp: new Date()
          });
          alert('Feedback erfolgreich gesendet');
          navigate('/profile');
        // }
      } catch (error) {
        console.error('Error sending feedback:', error);
        alert('Fehler beim Senden des Feedbacks');
      }
    }
  };

  return (
    <div className="send-feedback-container">
      {/* <div className="send-feedback-header">
        <h1>KONTAKT / FEEDBACK</h1>
      </div> */}
      <div className="send-feedback-form">
        <p>Neh Sie mitns takt auf. Schben Sie uns Ihre penlichen Eindrücke oder noch offenen Fragen. Wir freuen uns auf Ihre Nachricht und Ihr Feedback.</p>
        <textarea
          className="send-feedback-textarea"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="Kontakt / Feedback"
          rows={10}
        />
        <div className="send-feedback-buttons">
          <button onClick={handleSendFeedback} className="send-feedback-button">Senden</button>
          <button onClick={() => navigate('/profile')} className="send-feedback-button cancel-button">Abbrechen</button>
        </div>
      </div>
    </div>
  );
};

export default SendFeedback;
