import React, { useState } from 'react';
import './Analytics.css';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  BarChart,
  Bar,
  Legend,
  PieChart,
  Pie,
  Cell
} from 'recharts';

const Analytics = () => {
  const [selectedRange, setSelectedRange] = useState<'7d' | '30d' | '90d'>('7d');
  const [selectedCategory, setSelectedCategory] = useState<'Alle' | 'SUV' | 'Limousine' | 'Kombi'>('Alle');
  const [selectedRegion, setSelectedRegion] = useState<'Alle' | 'Berlin' | 'München' | 'Hamburg'>('Alle');

  const impressionsData: Record<'7d' | '30d' | '90d', { day: string; impressions: number; category: 'SUV' | 'Limousine' | 'Kombi'; region: 'Berlin' | 'München' | 'Hamburg' }[]> = {
    '7d': [
      { day: 'Mo', impressions: 180, category: 'SUV', region: 'Berlin' },
      { day: 'Di', impressions: 120, category: 'SUV', region: 'München' },
      { day: 'Mi', impressions: 150, category: 'Kombi', region: 'Berlin' },
      { day: 'Do', impressions: 100, category: 'Limousine', region: 'Hamburg' },
      { day: 'Fr', impressions: 200, category: 'SUV', region: 'Hamburg' },
      { day: 'Sa', impressions: 80, category: 'Kombi', region: 'München' },
      { day: 'So', impressions: 142, category: 'Limousine', region: 'Berlin' }
    ],
    '30d': [
      { day: 'Woche 1', impressions: 300, category: 'SUV', region: 'Berlin' },
      { day: 'Woche 2', impressions: 350, category: 'Limousine', region: 'München' },
      { day: 'Woche 3', impressions: 220, category: 'Kombi', region: 'Hamburg' },
      { day: 'Woche 4', impressions: 150, category: 'SUV', region: 'Berlin' }
    ],
    '90d': [
      { day: 'Jan', impressions: 820, category: 'SUV', region: 'Hamburg' },
      { day: 'Feb', impressions: 960, category: 'Limousine', region: 'Berlin' },
      { day: 'März', impressions: 740, category: 'Kombi', region: 'München' }
    ]
  };

  const filteredData = impressionsData[selectedRange].filter(entry =>
    (selectedCategory === 'Alle' || entry.category === selectedCategory) &&
    (selectedRegion === 'Alle' || entry.region === selectedRegion)
  );

  const contactData = [
    { boost: 'Normal', contacts: 52 },
    { boost: 'Mit Boost', contacts: 123 }
  ];

  const regionHeatmap = ['Berlin', 'München', 'Hamburg'].map(region => ({
    name: region,
    value: impressionsData[selectedRange].filter(d => d.region === region).reduce((sum, d) => sum + d.impressions, 0)
  }));

  const COLORS = ['#8884d8', '#82ca9d', '#ffc658'];

  return (
    <div className="analytics-page">
      <h1>Analytics</h1>
      <p>Behalte die Entwicklung deiner Inserate im Blick.</p>

      <div className="analytics-filter">
        <label htmlFor="range">Zeitraum:</label>
        <select
          id="range"
          value={selectedRange}
          onChange={(e) => setSelectedRange(e.target.value as '7d' | '30d' | '90d')}
        >
          <option value="7d">Letzte 7 Tage</option>
          <option value="30d">Letzte 30 Tage</option>
          <option value="90d">Letzte 90 Tage</option>
        </select>

        <label htmlFor="category">Fahrzeugtyp:</label>
        <select
          id="category"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value as 'Alle' | 'SUV' | 'Limousine' | 'Kombi')}
        >
          <option value="Alle">Alle</option>
          <option value="SUV">SUV</option>
          <option value="Limousine">Limousine</option>
          <option value="Kombi">Kombi</option>
        </select>

        <label htmlFor="region">Region:</label>
        <select
          id="region"
          value={selectedRegion}
          onChange={(e) => setSelectedRegion(e.target.value as 'Alle' | 'Berlin' | 'München' | 'Hamburg')}
        >
          <option value="Alle">Alle</option>
          <option value="Berlin">Berlin</option>
          <option value="München">München</option>
          <option value="Hamburg">Hamburg</option>
        </select>
      </div>

      <div className="analytics-grid">
        <div className="analytics-card">
          <h2>📈 Impressionen</h2>
          <p>{
            filteredData.reduce((sum, d) => sum + d.impressions, 0).toLocaleString()
          } im gewählten Zeitraum</p>
        </div>
        <div className="analytics-card">
          <h2>👀 Aufrufe</h2>
          <p>947 Fahrzeug-Detailansichten</p>
        </div>
        <div className="analytics-card">
          <h2>📩 Kontaktaufnahmen</h2>
          <p>123 Direktnachrichten erhalten</p>
        </div>
        <div className="analytics-card">
          <h2>✨ Boost-Wirkung</h2>
          <p>+61% mehr Sichtbarkeit durch aktive Boosts</p>
        </div>
      </div>

      <div className="analytics-charts">
        <div className="chart-block">
          <h3>Impressionen: Verlauf</h3>
          <ResponsiveContainer width="100%" height={250}>
            <LineChart data={filteredData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="day" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="impressions"
                stroke="#ff6f3c"
                strokeWidth={2}
                isAnimationActive={true}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-block">
          <h3>Kontaktaufnahmen: Boost vs. Normal</h3>
          <ResponsiveContainer width="100%" height={250}>
            <BarChart data={contactData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="boost" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="contacts" fill="#ff6f3c" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-block">
          <h3>Impressionen nach Region</h3>
          <ResponsiveContainer width="100%" height={250}>
            <PieChart>
              <Pie
                data={regionHeatmap}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}
                label
              >
                {regionHeatmap.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
