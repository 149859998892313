// import { initializeApp, FirebaseApp } from 'firebase/app';
// import { getAuth, Auth, GoogleAuthProvider } from 'firebase/auth';
// import { getFirestore, Firestore } from 'firebase/firestore';
// import { getStorage, FirebaseStorage } from 'firebase/storage';
// import { getMessaging, Messaging, getToken, MessagePayload, onMessage } from 'firebase/messaging';
// import { useContext, useEffect, useState } from 'react';
// import { FirebaseConfigContext } from './FirebaseConfigContext'; // Context für Firebase-Konfigurationsdaten

// interface FirebaseServices {
//   app: FirebaseApp;
//   auth: Auth;
//   googleProvider: GoogleAuthProvider;
//   db: Firestore;
//   storage: FirebaseStorage;
//   messaging?: Messaging;
// }

// const useFirebaseServicesNew = (): FirebaseServices | null => {
//   const firebaseConfig = useContext(FirebaseConfigContext); // Hole Firebase-Konfiguration aus dem Kontext
//   const [firebaseApp, setFirebaseApp] = useState<FirebaseApp | null>(null);
//   const [auth, setAuth] = useState<Auth | null>(null);
//   const [db, setDb] = useState<Firestore | null>(null);
//   const [storage, setStorage] = useState<FirebaseStorage | null>(null);
//   const [googleProvider, setGoogleProvider] = useState<GoogleAuthProvider | null>(null);
//   const [messaging, setMessaging] = useState<Messaging | null>(null);

//   useEffect(() => {
//     if (firebaseConfig && !firebaseApp) {
//       const app = initializeApp(firebaseConfig); // Initialisiere Firebase mit dynamischer Konfiguration
//       setFirebaseApp(app);
//       setAuth(getAuth(app));
//       setDb(getFirestore(app));
//       setStorage(getStorage(app));
//       setGoogleProvider(new GoogleAuthProvider());
//       // Optional: Füge das Firebase Messaging hinzu, falls erforderlich
//       // const messagingInstance = getMessaging(app);
//       // setMessaging(messagingInstance);
//     }
//   }, [firebaseConfig, firebaseApp]);

//   if (!firebaseApp || !auth || !db || !storage || !googleProvider) {
//     return null;
//   }

//   return { app: firebaseApp, auth, googleProvider, db, storage };
// };

// // Funktion zum Anfordern von Berechtigungen für Benachrichtigungen
// // const requestNotificationPermission = async (messaging: Messaging | null) => {
// //   if (messaging) {
// //     try {
// //       await Notification.requestPermission();
// //       const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });
// //       if (token) {
// //         console.log('FCM Token:', token);
// //         return token;
// //       }
// //     } catch (error) {
// //       console.error('Unable to get permission to notify.', error);
// //     }
// //   }
// //   return null;
// // };

// // Funktion zum Anzeigen von Benachrichtigungen
// // const showNotification = (payload: MessagePayload) => {
// //   if (Notification.permission === 'granted' && payload.notification) {
// //     const { title, body } = payload.notification;
// //     if (title && body) {
// //       new Notification(title, { body });
// //     }
// //   }
// // };

// // Registrierung des Service Workers für Firebase Messaging
// // if ('serviceWorker' in navigator) {
// //   navigator.serviceWorker.register('/firebase-messaging-sw.js')
// //     .then((registration) => {
// //       console.log('Service Worker registration successful with scope: ', registration.scope);
// //     }).catch((err) => {
// //       console.log('Service Worker registration failed: ', err);
// //     });
// // }

// // export { useFirebaseServicesNew, requestNotificationPermission, showNotification };
// export { useFirebaseServicesNew };



// import { useState, useEffect } from 'react';
// import { initializeApp, FirebaseApp } from 'firebase/app';
// import { getAuth, Auth, GoogleAuthProvider } from 'firebase/auth';
// import { getFirestore, Firestore } from 'firebase/firestore';
// import { getStorage, FirebaseStorage } from 'firebase/storage';
// import { Messaging } from 'firebase/messaging';

// interface FirebaseServices {
//   app: FirebaseApp;
//   auth: Auth;
//   googleProvider: GoogleAuthProvider;
//   db: Firestore;
//   storage: FirebaseStorage;
//   messaging?: Messaging;
// }

// const firebaseConfig = {
//   apiKey: 'AIzaSyAI9dx0ToBfUO7_bHphgcjVtBdzqKcJfS0',
//   authDomain: 'automatch-dev.firebaseapp.com',
//   projectId: 'automatch-dev',
//   storageBucket: 'automatch-dev.appspot.com',
//   messagingSenderId: '585373068068',
//   appId: '1:585373068068:web:7e57522d3791ec71f861b1'
// };

// const useFirebaseServicesNew = (): FirebaseServices | null => {
//   const [firebaseApp, setFirebaseApp] = useState<FirebaseApp | null>(null);
//   const [auth, setAuth] = useState<Auth | null>(null);
//   const [db, setDb] = useState<Firestore | null>(null);
//   const [storage, setStorage] = useState<FirebaseStorage | null>(null);
//   const [googleProvider, setGoogleProvider] = useState<GoogleAuthProvider | null>(null);
//   const [messaging, setMessaging] = useState<Messaging | null>(null);

//   useEffect(() => {
//     if (!firebaseApp) {
//       // Initialisiere Firebase mit der statischen Konfiguration
//       const app = initializeApp(firebaseConfig);
//       setFirebaseApp(app);
//       setAuth(getAuth(app));
//       setDb(getFirestore(app));
//       setStorage(getStorage(app));
//       setGoogleProvider(new GoogleAuthProvider());
//       // Optional: Initialisiere Firebase Messaging, falls erforderlich
//       // const messagingInstance = getMessaging(app);
//       // setMessaging(messagingInstance);
//     }
//   }, [firebaseApp]);

//   if (!firebaseApp || !auth || !db || !storage || !googleProvider) {
//     return null;
//   }

//   return { app: firebaseApp, auth, googleProvider, db, storage };
// };

// export { useFirebaseServicesNew };





import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAuth, Auth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore, Firestore } from 'firebase/firestore';
import { getStorage, FirebaseStorage } from 'firebase/storage';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
// Optional: Messaging importieren, falls benötigt
// import { getMessaging, Messaging } from 'firebase/messaging';

declare global {
  interface Window {
    FIREBASE_APPCHECK_DEBUG_TOKEN?: boolean | string;
  }
}

// Firebase-Konfigurationswerte (statisch, wie von dir bereitgestellt)
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

// Initialisiere die Firebase App
const app: FirebaseApp = initializeApp(firebaseConfig);

window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

// Initialisiere App Check mit ReCaptcha v3
// initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider("6LdkYHQqAAAAALG-uYjXCuNjzASVKVVKu8GzJeR8"), // Verwende hier deinen ReCaptcha-Schlüssel
//   isTokenAutoRefreshEnabled: true, // Aktiviert die automatische Token-Aktualisierung
// });

// Initialisiere Firebase-Dienste
const auth: Auth = getAuth(app);
const googleProvider: GoogleAuthProvider = new GoogleAuthProvider();
const db: Firestore = getFirestore(app);
const storage: FirebaseStorage = getStorage(app);

export { app, auth, googleProvider, db, storage };


