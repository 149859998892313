import React, { useState } from 'react';
import './Filters.css';

const Filters = () => {
  const [filters, setFilters] = useState([
    {
      id: '1',
      title: 'E-Autos bis 30.000 €',
      tags: ['Elektro', 'bis 30.000 €', 'Tageszulassung'],
      matches: 12,
    },
    {
      id: '2',
      title: 'SUVs mit Allrad',
      tags: ['SUV', 'Allrad', 'max. 100.000 km'],
      matches: 7,
    },
  ]);

  const [newTitle, setNewTitle] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [priceFrom, setPriceFrom] = useState('');
  const [priceTo, setPriceTo] = useState('');
  const [location, setLocation] = useState('');
  const [fuelType, setFuelType] = useState('');
  const [firstRegistration, setFirstRegistration] = useState('');
  const [activeFilter, setActiveFilter] = useState<string | null>(null);

  const handleAddFilter = () => {
    if (!newTitle.trim()) return;

    const tags = [
      vehicleType && `${vehicleType}`,
      priceFrom && `ab ${priceFrom} €`,
      priceTo && `bis ${priceTo} €`,
      location && location,
      fuelType && fuelType,
      firstRegistration && `EZ ab ${firstRegistration}`,
    ].filter(Boolean);

    const newFilter = {
      id: Date.now().toString(),
      title: newTitle,
      tags,
      matches: Math.floor(Math.random() * 20 + 1),
    };
    setFilters([newFilter, ...filters]);
    setNewTitle('');
    setVehicleType('');
    setPriceFrom('');
    setPriceTo('');
    setLocation('');
    setFuelType('');
    setFirstRegistration('');
  };

  const handleDeleteFilter = (id: string) => {
    setFilters(filters.filter((filter) => filter.id !== id));
    if (activeFilter === id) setActiveFilter(null);
  };

  const handleApplyFilter = (id: string) => {
    setActiveFilter(id);
    console.log('Angewendeter Filter:', filters.find(f => f.id === id));
    // Hier kannst du z. B. ein redirect, eine API-Anfrage oder eine Filterfunktion einbauen
  };

  return (
    <div className="filters-page">
      <h1>📍 Gespeicherte Filter</h1>
      <p>Hier kannst du deine vordefinierten Filter einsehen und verwalten.</p>

      <div className="filter-form">
        <input
          type="text"
          placeholder="Filtertitel z. B. ‚E-Autos unter 30.000 €‘"
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
        />

        <select value={vehicleType} onChange={(e) => setVehicleType(e.target.value)}>
          <option value="">Fahrzeugtyp wählen</option>
          <option value="SUV">SUV</option>
          <option value="Limousine">Limousine</option>
          <option value="Kombi">Kombi</option>
          <option value="Cabrio">Cabrio</option>
          <option value="Elektro">Elektro</option>
        </select>

        <div className="price-range">
          <input
            type="number"
            placeholder="Preis von (€)"
            value={priceFrom}
            onChange={(e) => setPriceFrom(e.target.value)}
          />
          <input
            type="number"
            placeholder="Preis bis (€)"
            value={priceTo}
            onChange={(e) => setPriceTo(e.target.value)}
          />
        </div>

        <input
          type="text"
          placeholder="Ort / Region"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />

        <select value={fuelType} onChange={(e) => setFuelType(e.target.value)}>
          <option value="">Antriebsart wählen</option>
          <option value="Elektro">Elektro</option>
          <option value="Hybrid">Hybrid</option>
          <option value="Benzin">Benzin</option>
          <option value="Diesel">Diesel</option>
        </select>

        <input
          type="month"
          placeholder="Erstzulassung ab"
          value={firstRegistration}
          onChange={(e) => setFirstRegistration(e.target.value)}
        />

        <button className="btn-add" onClick={handleAddFilter}>➕ Filter speichern</button>
      </div>

      <div className="filter-list">
        {filters.map((filter) => (
          <div className={`filter-card${filter.id === activeFilter ? ' active' : ''}`} key={filter.id}>
            <h3>{filter.title}</h3>
            <div className="tag-list">
              {filter.tags.map((tag, index) => (
                <span className="tag" key={index}>{tag}</span>
              ))}
            </div>
            <span className="match-count">🔎 {filter.matches} passende Inserate</span>
            <div className="filter-actions">
              <button className="btn-apply" onClick={() => handleApplyFilter(filter.id)}>Anwenden</button>
              <button className="btn-delete" onClick={() => handleDeleteFilter(filter.id)}>Löschen</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Filters;
