import React from 'react';
import './Boost.css';

const Boost = () => {
    const boostOptions = [
        {
            id: 'highlight',
            title: 'Highlight Boost',
            description: 'Hebe dein Inserat farblich hervor und erhöhe die Sichtbarkeit in der Ergebnisliste.',
            price: '4,99 €',
            icon: '✨'
        },
        {
            id: 'toplisting',
            title: 'Top Listing',
            description: 'Platziere dein Inserat 7 Tage lang ganz oben in der Suche.',
            price: '9,99 €',
            icon: '📌'
        },
        {
            id: 'fastmatch',
            title: 'Sofort-Match',
            description: 'Dein Inserat wird passenden Käufern sofort als „besonders relevant“ angezeigt.',
            price: '6,99 €',
            icon: '🚀'
        }
    ];

    return (
        <div className="boost-page">
            <h1>Boost Your Listings</h1>
            <p>Bring dein Inserat ganz nach vorne – mit unseren Power-Boosts für mehr Reichweite und bessere Matches.</p>

            <div className="boost-suggestion-box">
                <h2>💡 12 passende Käufer:innen warten schon</h2>
                <p>Basierend auf deinem Inserat erkennen wir 12 Interessenten mit hoher Übereinstimmung.</p>
                <p><strong>Boost jetzt</strong>, damit sie dein Fahrzeug zuerst sehen!</p>
                <div className="boost-suggestion-actions">
                    <button className="boost-btn">Jetzt Boost aktivieren</button>
                </div>

            </div>

            <div className="boost-grid">
                {boostOptions.map((boost) => (
                    <div className="boost-card" key={boost.id}>
                        <div className="boost-icon">{boost.icon}</div>
                        <h3>{boost.title}</h3>
                        <p>{boost.description}</p>
                        <div className="boost-price">{boost.price}</div>
                        <button className="boost-btn">Jetzt aktivieren</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Boost;
