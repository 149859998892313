import React from 'react';
import './Dashboard.css';

const Dashboard = () => {
  return (
    <div className="dashboard-page">
      <h1>Willkommen bei AutoMatch PRO</h1>
      <p>Dein zentraler Überblick – alle wichtigen KPIs auf einen Blick.</p>

      <div className="kpi-grid">
        <div className="kpi-card">
          <h2>🚗 18 Inserate aktiv</h2>
          <p>Aktuelle Anzahl deiner Live-Anzeigen</p>
        </div>
        <div className="kpi-card">
          <h2>💬 7 neue Nachrichten</h2>
          <p>Seit deinem letzten Login</p>
        </div>
        <div className="kpi-card">
          <h2>👥 24 Matches</h2>
          <p>Suchprofile, die zu deinen Inseraten passen</p>
        </div>
        <div className="kpi-card">
          <h2>📈 1.532 Impressionen</h2>
          <p>In den letzten 7 Tagen</p>
        </div>
      </div>

      <div className="insight-section">
        <h2>Performance Insights</h2>
        <div className="insight-grid">
          <div className="insight-card">
            <h3>Top Fahrzeug</h3>
            <p>BMW i3 – 523 Impressionen diese Woche</p>
          </div>
          <div className="insight-card">
            <h3>Beliebteste Kategorie</h3>
            <p>SUVs – 38% aller Suchanfragen</p>
          </div>
          <div className="insight-card">
            <h3>Durchschnittliche Antwortzeit</h3>
            <p>2,3 Stunden – Schnellantwort aktiv</p>
          </div>
          <div className="insight-card">
            <h3>Boost Performance</h3>
            <p>+61% mehr Aufrufe dank aktiver Boosts</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
